<template>
  <Layer
    class="box_drap_option_select"
    v-if="isShowLayer"
    :title="$poData.trans_filter_badge_optionsheet_title"
    :isCloseBtn="true"
    @onClickClose="$emit('onClickClose')">
    <strong class="tit_category">{{ $poData.trans_filter_badge_optionsheet_heading_1 }}</strong>
    <RadioBox
      name="transTypeValue"
      :dataList="transTypeDataList"
      :value.sync="transTypeValueSync"
      @update:value="value => onUpdateTransType(value)"/>
    <template v-if="transTypeValueSync != 'TOTAL'">
      <strong class="tit_category">{{ $poData.trans_filter_badge_optionsheet_heading_2 }}</strong>
      <RadioBox
        name="categoryValue"
        :dataList="categoryDataList[transTypeValueSync]"
        :value.sync="categoryValueSync"
        :isImg="true"/>
    </template>
    <div class="area_float">
      <Button
        btnStyle="primary"
        :text="$poData.trans_filter_badge_optionsheet_btn"
        @onClickBtn="onClickCompelete()"></Button>
    </div>
    </Layer>
</template>

<script>
import Layer from '@/components/layout/popup/Layer';
import RadioBox from '@/components/common/radio/RadioBox';
import Button from '@/components/common/button/Button';

export default {
  name: 'OptionSelectPopup',
  props:{
    transTypeDataList: Array,
    categoryDataList: Object,
    transTypeValue: String,
    categoryValue: String,
  },
  components:{
    Layer,
    RadioBox,
    Button
  },
  mounted(){
    const _this = this;
    this.timeoutID0 = setTimeout(function(){
      _this.isShowLayer = true;
      this.timeoutID0 = null;
    }, 50);
    this.transTypeValueSync = this.transTypeValue;
    this.categoryValueSync = this.categoryValue;
  },
  unmounted(){
    document.body.style.overflow = "";
  },
  data(){
    return{
      isShowLayer:false,
      transTypeValueSync: '',
      categoryValueSync: '',
    }
  },
  // computed:{
  //   transTypeValueSync:{
  //     get(){
  //       return this.transTypeValue;
  //     },
  //     set(val){
  //       this.$emit('update:transTypeValue',val)
  //     },
  //   },
  //   categoryValueSync:{
  //     get(){
  //       return this.categoryValue;
  //     },
  //     set(val){
  //       this.$emit('update:categoryValue',val)
  //     },
  //   }
  // },
  methods:{
    onUpdateTransType(value){
      switch(value){
        case 'TOTAL' : { this.categoryValueSync = ''; break }
        case 'WITHDRAW' : { this.categoryValueSync = 'WITHDRAW_TOTAL'; break }
        case 'DEPOSIT' : { this.categoryValueSync = 'DEPOSIT_TOTAL'; break }
      }
    },
    onClickCompelete(){
      document.body.style.overflow = "";
      this.$emit('onClickCompelete',this.transTypeValueSync, this.categoryValueSync)
    }
  }
}
</script>

<style scoped>
.box_drap_option_select >>> .body_drop{padding-bottom:112px}
.area_float{position:fixed;bottom:0;left:0;right:0;z-index:10;padding:16px 20px 34px;background-color:#fff}
.tit_category{display:block;font-weight:500;font-size:16px;line-height:24px;color:#666}
.group_radio_un_img + .tit_category{margin-top:16px}
.tit_category + .group_radio_un_img,
.tit_category + .group_radio_img{margin-top:14px}
.group_radio_un_img + button,
.group_radio_img + button{margin-top:24px}
</style>