import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import router from '@/router';
import TransListModel from '@/views/v2/transaction/model/TransListModel';
import poData from '@/constants/poData.js';
import { convertIdToText } from "@/utils/selectDataUtils";

export default class TransListViewModel {
  constructor() {
    this.model = new TransListModel();
    this.query = {};
    this.childUid = ''; // 부모가 보는 자녀일때 자녀 uid
    this.isOptionSelectPopup = false;

    this.emptyDesc = poData.trans_list_empty // 내역 없을시 문구
    this.has_return_btn = false; // 자녀 지갑 메인 '가족대표에게 송금하기'버튼 유무
  }
  init(query){
    this.query = query;
    const { transactionYearMonth, transTypeValue, searchOption, transactionName, childUid, has_return_btn, listBackPage } = query;
    if(transactionYearMonth || transTypeValue || searchOption){
      this.model.setSearchOptionValue(transTypeValue, searchOption, transactionYearMonth, transactionName);
    }
    if(childUid){
      this.childUid = childUid;
    }
    if(listBackPage){
      console.log(listBackPage)
      query.backPage = listBackPage;
      delete query.listBackPage;
      router.replace({'query': query}).catch(()=>{});
    }
    this.getTransList();
    
    this.has_return_btn = has_return_btn && String(has_return_btn) === 'true';
  }

  onClickOptionSelectPopup(){
    this.isOptionSelectPopup = true
  }
  onClickOptionSelectPopupClose(){
    this.isOptionSelectPopup = false
  }
  onClickOptionSelectPopupCompelete(transTypeValue, categoryValue){
    this.isOptionSelectPopup = false;
    this.model.setSearchOptionValue(transTypeValue, categoryValue);
    this.onSearch();
  }


  onSearch(val){
    const transactionName = val === undefined ? this.model.searchData.option.transactionName : val;
    this.model.searchData.option.transactionName = transactionName;
    this.getTransList();
  }
  onSearchCancle(){
    this.model.searchData.isSearchOpen = false;
    this.model.searchData.value = ''
    this.model.searchData.option.transactionName = '';
    this.getTransList();
  }

  onClickDetail(transaction_uid){
    let newQuery = JSON.parse(JSON.stringify(router.app._route.query));
    newQuery.userType = this.model.userType;
    if(newQuery.backPage){
      newQuery.listBackPage = newQuery.backPage;
      delete newQuery.backPage;
    }
    router.push({name:'TransDetailV2', params:{ id : transaction_uid }, query: newQuery});
  }

  setQuery(addQuery){
    let currentQuery = JSON.parse(JSON.stringify(router.app._route.query));
    currentQuery.transTypeValue = this.model.uppperTabValue;
    if(this.model.userType != 'PARENTS'){
      currentQuery.transCategory = this.model.transTypeValue;
    }
    let newQuery = Object.fromEntries(Object.entries(Object.assign(currentQuery,addQuery)).filter(([_, v]) => v != '' && v != undefined  && v != 'undefined' ));
    router.replace({'query': newQuery}).catch(()=>{});
  }

  setEmptyDesc(){
    const transactionYearMonth = this.model.searchData.option.transactionYearMonth;
    const year_month_list = this.model.year_month_list;
    const year_month_text =  convertIdToText(transactionYearMonth,year_month_list);
    const poDataEmptyDesc = poData.trans_list_empty;
    if(transactionYearMonth && year_month_list.length > 0 && year_month_text){
      this.emptyDesc = year_month_text+' '+poDataEmptyDesc;
    }else{
      this.emptyDesc = poDataEmptyDesc;
    }
  }

  getTransList(){
    const query = makeQueryStringByObject(this.model.searchData.option);
    let path = '';
    switch(this.model.userType){
      case 'PARENTS': {
        path = `${apiPath.TRANS_LIST_PARENTS}${query}`;
        break;
      }
      case 'CHILD': {
        path = `${apiPath.TRANS_LIST_CHILD}${query}`;
        break;
      }
      case 'PARENTS_CHILD': {
        path = `${apiPath.TRANS_LIST_PARENTS_CHILD.format(this.childUid)}${query}`;
        break;
      }
    }
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.setQuery(this.model.searchData.option);
      this.model.setData(resultData);
      this.setEmptyDesc();
    }, (failed) => {
    })
  }
}