
import { setDataList, setCurrentValue } from "@/utils/selectDataUtils";
import poData from '@/constants/poData.js';

export default class TransListModel {
  constructor(){
    this.searchData = {
      isSearchOpen: false,
      placeholder : poData.trans_list_search,
      value:'', // 검색 키워드 (입력용)
      option: {
        transactionName:'', // 검색 키워드 (검색용)
        transactionYearMonth:'', // 검색 년월 YYYYMM
        searchOption:'', // 검색옵션
      },
      searchOptionText:'', // 검색옵션 text
    }
    this.user = {
      account_id : "",
      name : "",
      nick_name : "",
      profile_image_url : null,
      role : "",
      status : "",
      user_uid : null,
    };
    this.userType = ''; // PARENTS || CHILD || PARENTS_CHILD
    this.transaction_list = []; // 거래내역 목록
    this.pagination = {}; // 거래내역 페이징
    this.year_month_list = []; // 검색 년월 데이터 목록
    this.option_list = []; // 검색 옵션 데이터 목록

    this.transTypeDataList = [ // 검색 옵션 상위 카테고리 데이터 목록 + 이전 페이지 탭 값
      { id:'TOTAL', text:'수입+소비', uppperTabValue:'' },
      { id:'WITHDRAW', text:'소비', uppperTabValue:'spending' },
      { id:'DEPOSIT', text:'수입', uppperTabValue:'income' },
    ];
    this.categoryDataList = { // 검색 옵션 상위 카테고리별 데이터 목록
      TOTAL:[],
      WITHDRAW:[],
      DEPOSIT:[],
    };
    this.transTypeValue ='TOTAL'; // 상위 카테고리 값
    this.uppperTabValue = ''; // 이전 페이지 탭 값
    this.transTypeValueText = '수입+소비'; // 상위 카테고리 값 text
    this.categoryValue = ''; // 검색 옵션 값
  }
  setData(obj){
    const { pagination } = obj;
    const { user, transaction_list, year_month_list, option_list } = obj.body;
    this.user = user;
    this.transaction_list = transaction_list;
    this.pagination = pagination;
    this.year_month_list = setDataList(year_month_list,'value','display_name');
    this.option_list = setDataList(option_list,'value','display_name');
    this.searchData.option.transactionYearMonth = setCurrentValue(year_month_list);
    this.searchData.option.searchOption = setCurrentValue(option_list);
    this.setCategoryDataList();
  }
  setCategoryDataList(){
    this.transTypeDataList.forEach(transTypeItem => {
      this.categoryDataList[transTypeItem.id] = this.option_list.filter(item => item.group_name === transTypeItem.id);
     });
    
    if(this.searchData.option.searchOption && !this.searchData.searchOptionText){
      this.setSearchOptionText();
    }
  }
  setSearchOptionValue(transTypeValue,categoryValue,transactionYearMonth, transactionName){
    if(transTypeValue){
      const isUpperTabValue = this.transTypeDataList.find(item => item.uppperTabValue === transTypeValue);
      const transTypeData = isUpperTabValue ? isUpperTabValue : this.transTypeDataList.find(item => item.id === transTypeValue);
      const transTypeValueFormat = isUpperTabValue ? isUpperTabValue.id : transTypeValue;
      this.transTypeValue = transTypeValueFormat;
      this.transTypeValueText = transTypeData.text;
      this.uppperTabValue = transTypeData.uppperTabValue;
    }
    this.categoryValue = categoryValue;
    this.searchData.option.transactionYearMonth = transactionYearMonth || this.searchData.option.transactionYearMonth;
    this.searchData.option.transactionName = transactionName || '';
    this.searchData.value = transactionName || '';
    if(transactionName){
      this.searchData.isSearchOpen = true;
    }
    
    this.searchData.option.searchOption = categoryValue ? categoryValue : '';
    this.setSearchOptionText();
  }
  setSearchOptionText(){
    const categoryData = this.categoryDataList[this.transTypeValue].find(item => item.id === this.categoryValue);
    if(( this.transTypeValueText && !this.categoryValue ) || ( this.categoryValue && categoryData )){
      this.searchData.searchOptionText = `${this.transTypeValueText}${categoryData && this.transTypeValueText != '수입+소비' ? ': '+categoryData.text : ''}`;
    }
  }
}