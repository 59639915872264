<template>
  <PageWithLayout
    :isBodyFull="true"
    :isBackBtn="true"
    :backRouteName="backRouteName"
    :backRouteQuery="backRouteQuery"
    :backPage="backPage"
    :backPageData="backPageData"
    :isTitle="true"
    :title="$poData.trans_list_title.format(model.user.name)"
    :searchData.sync="model.searchData"
    @onSearch="value => $emit('onSearch',value)">
    <div :class="['area_fixed_under_header', { 'has_total' : isShowTotal }]">
      <slot name="filter_option" />
      <div v-if="isShowTotal" class="info_total area_overflow">
        <div class="area_left">
          {{model.transTypeValueText}} 총합
        </div>
        <div class="area_right">
          {{ total | convertNumberToComma(false,'0','원') }}
        </div>
      </div>
    </div>
    <div
      v-if="model.pagination.total_elements > 0"
      class="list_trans">
      <ListItem
        v-for="(itemData, index) in model.transaction_list"
        :key="`transItem${index}`"
        :padding="24"
        :paddingRight="16"
        :thumbNail="{
          size: 54,
          url : itemData.profile_image_url
        }"
        :title="itemData.title"
        :leftSubText="itemData.message || itemData.memo"
        :leftSubTextColor="itemData.message ? 'primary' : 'gray'"
        :leftBadge="{
          value : itemData.label_display_name,
          type : 'sub1'
        }"
        :rightSubText="itemData.amount | convertNumberToComma(false,'0','원')"
        :isRightSubTextColor="itemData.amount >= 0 ? 'red' : ''"
        @onClickItem="$emit('onClickDetail',itemData.transaction_uid)">
        <template v-slot:rightSlot>
            <span class="txt_date">{{ itemData.sub_title }}</span>
        </template>
      </ListItem>
    </div>
    <template v-else>
      <p class="desc_empty">{{ emptyDesc || $poData.trans_list_empty }}</p>
    </template>
    <template v-slot:popup>
      <OptionSelectPopup
        v-if="isOptionSelectPopup"
        :transTypeDataList="model.transTypeDataList"
        :categoryDataList="model.categoryDataList"
        :transTypeValue="model.transTypeValue"
        :categoryValue="model.categoryValue"
        @onClickClose="$emit('onClickOptionSelectPopupClose')"
        @onClickCompelete="(transTypeValue, categoryValue) => $emit('onClickOptionSelectPopupCompelete',transTypeValue, categoryValue)" />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import ListItem from '@/components/common/list/ListItem';
import Selectbox from '@/components/common/selectbox/Selectbox';
import SelectboxCustom from '@/components/common/selectbox/SelectboxCustom';

import OptionSelectPopup from '@/views/v2/transaction/view/popup/OptionSelectPopup';

export default {
  name: 'TransListLayout',
  props:{
    backRouteName: String,
    backRouteQuery: Object,
    backPage: String,
    backPageData: Object,
    model: Object,
    isOptionSelectPopup: Boolean,
    emptyDesc: String,
  },
  components:{
    PageWithLayout,
    ListItem,
    Selectbox,
    SelectboxCustom,
    OptionSelectPopup,
  },
  computed:{
    isShowTotal(){
      return this.model.transTypeValue != 'TOTAL';
    },
    total(){
      const sumDataList = this.model.transaction_list;
      const sum = sumDataList.map(item => item.amount).reduce((a,b) => {
        return a + b
      },0);
      return sum < 0 ? sum *-1 : sum;
    }
  }
}
</script>

<style scoped>
.area_fixed_under_header >>> .selectbox_comm + .selectbox_comm{margin-left:8px}
.area_fixed_under_header .info_total{margin-top:36px;font-weight:700;font-size:16px;line-height:24px;color:#111}
.list_trans{padding-top:68px}
.area_fixed_under_header.has_total + .list_trans{padding-top:126px}
.desc_empty{position:absolute;top:50%;left:0;margin-top:-24px;width:100%}
</style>