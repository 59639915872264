var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWithLayout',{attrs:{"isBodyFull":true,"isBackBtn":true,"backRouteName":_vm.backRouteName,"backRouteQuery":_vm.backRouteQuery,"backPage":_vm.backPage,"backPageData":_vm.backPageData,"isTitle":true,"title":_vm.$poData.trans_list_title.format(_vm.model.user.name),"searchData":_vm.model.searchData},on:{"update:searchData":function($event){return _vm.$set(_vm.model, "searchData", $event)},"update:search-data":function($event){return _vm.$set(_vm.model, "searchData", $event)},"onSearch":value => _vm.$emit('onSearch',value)},scopedSlots:_vm._u([{key:"popup",fn:function(){return [(_vm.isOptionSelectPopup)?_c('OptionSelectPopup',{attrs:{"transTypeDataList":_vm.model.transTypeDataList,"categoryDataList":_vm.model.categoryDataList,"transTypeValue":_vm.model.transTypeValue,"categoryValue":_vm.model.categoryValue},on:{"onClickClose":function($event){return _vm.$emit('onClickOptionSelectPopupClose')},"onClickCompelete":(transTypeValue, categoryValue) => _vm.$emit('onClickOptionSelectPopupCompelete',transTypeValue, categoryValue)}}):_vm._e()]},proxy:true}])},[_c('div',{class:['area_fixed_under_header', { 'has_total' : _vm.isShowTotal }]},[_vm._t("filter_option"),(_vm.isShowTotal)?_c('div',{staticClass:"info_total area_overflow"},[_c('div',{staticClass:"area_left"},[_vm._v(" "+_vm._s(_vm.model.transTypeValueText)+" 총합 ")]),_c('div',{staticClass:"area_right"},[_vm._v(" "+_vm._s(_vm._f("convertNumberToComma")(_vm.total,false,'0','원'))+" ")])]):_vm._e()],2),(_vm.model.pagination.total_elements > 0)?_c('div',{staticClass:"list_trans"},_vm._l((_vm.model.transaction_list),function(itemData,index){return _c('ListItem',{key:`transItem${index}`,attrs:{"padding":24,"paddingRight":16,"thumbNail":{
        size: 54,
        url : itemData.profile_image_url
      },"title":itemData.title,"leftSubText":itemData.message || itemData.memo,"leftSubTextColor":itemData.message ? 'primary' : 'gray',"leftBadge":{
        value : itemData.label_display_name,
        type : 'sub1'
      },"rightSubText":_vm._f("convertNumberToComma")(itemData.amount,false,'0','원'),"isRightSubTextColor":itemData.amount >= 0 ? 'red' : ''},on:{"onClickItem":function($event){return _vm.$emit('onClickDetail',itemData.transaction_uid)}},scopedSlots:_vm._u([{key:"rightSlot",fn:function(){return [_c('span',{staticClass:"txt_date"},[_vm._v(_vm._s(itemData.sub_title))])]},proxy:true}],null,true)})}),1):[_c('p',{staticClass:"desc_empty"},[_vm._v(_vm._s(_vm.emptyDesc || _vm.$poData.trans_list_empty))])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }